@charset 'utf-8';
.wx-image-viewer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.wx-image-viewer .viewer-cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
}
.wx-image-viewer .viewer-list-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.wx-image-viewer .viewer-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.wx-image-viewer .viewer-image-container img {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: left top;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.wx-image-viewer .viewer-image-pointer {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}
.wx-image-viewer .viewer-image-pointer .pointer {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 100%;
  background-color: #333;
}
.wx-image-viewer .viewer-image-pointer .pointer.on {
  background-color: #fff;
}
.wx-image-viewer .viewer-image-loading {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 100%;
  border-width: 4px;
  border-style: solid;
  border-color: #333;
  border-bottom-color: #FFF;
  -webkit-animation: roll 1s linear infinite;
  animation: roll 1s linear infinite;
}
@-webkit-keyframes roll {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes roll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
